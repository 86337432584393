import { Box, Skeleton, Toolbar, Typography, styled } from "@mui/material";
import MuiAppBar, {
  type AppBarProps as MuiAppBarProps,
} from "@mui/material/AppBar";
import * as React from "react";
import { Outlet } from "react-router-dom";
import { useDocumentTitle, useLocalStorage } from "usehooks-ts";
import { useAuthenticated, useHasPermission } from "../../hooks/authHooks";
import { useActiveLink } from "../../hooks/routeHooks";
import { NoPermission } from "./noPermission";
import { useState } from "react";

const DarkModeSelector = React.lazy(() => import("./darkModeSelector"));
const ApplicationStatus = React.lazy(() => import("../applicationStatus"));
const InformationButton = React.lazy(() => import("./informationButton"));
const CompetenceMenuItem = React.lazy(
  () => import("../../views/competence/competenceMenu"),
);
const Menu = React.lazy(() => import("../menu"));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  width: `calc(100% - (${theme.spacing(8)} + 1px))`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const MainLayout: React.FC = () => {
  const [menuOpen, setMenuOpen] = useLocalStorage("menu-open", true);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const authenticated = useAuthenticated();
  const activeLink = useActiveLink();
  const hasPermission = useHasPermission();
  const hasViewPermission =
    !activeLink?.permission || hasPermission(activeLink.permission);
  useDocumentTitle(`${activeLink?.label} - Digero`);

  const handleUpdateTrigger = () => {
    setUpdateTrigger((prev) => prev + 1);
  };

  return (
    <>
      <AppBar position="fixed" open={menuOpen}>
        <Toolbar>
          <Typography sx={{ flexGrow: 1 }}>
            {activeLink?.label}
            <InformationButton />
          </Typography>
          <CompetenceMenuItem updateTrigger={updateTrigger} />
          <DarkModeSelector />
          <ApplicationStatus />
        </Toolbar>
      </AppBar>
      <Menu open={menuOpen} handleClose={() => setMenuOpen(!menuOpen)} />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        {!authenticated && <Skeleton />}
        {authenticated && !hasViewPermission && <NoPermission />}
        {authenticated && hasViewPermission && (
          <Outlet context={{ handleUpdateTrigger }} />
        )}
      </Box>
    </>
  );
};
