import type { Theme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    beige: Palette["primary"];
  }

  interface PaletteOptions {
    beige?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    beige: true;
  }
}

export const addBeige = (theme: Theme) =>
  theme.palette.augmentColor({
    color: { main: "#ebdc9b" },
    name: "beige",
  });
