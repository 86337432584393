import { hasOwnProperty } from "./helpers/utils";
import type { Analysis, IAnalysis } from "./models/analysis";
export type FormulaResult = {
  value: number;
  blocked?: boolean;
  minlimit?: boolean;
};

export type IFormula = (
  analysis: Analysis,
  analyses: readonly Analysis[],
  otherAnalyses?: readonly Analysis[],
) => FormulaResult;
export type RawFormula<T extends readonly string[]> = (
  analysis: IAnalysis,
  analyses: { [K in T[number]]: IAnalysis | undefined },
  valid: boolean,
  blocked: boolean,
) => FormulaResult;

export type Formula<T extends readonly string[] = readonly string[]> = {
  formula: RawFormula<T>;
  dependencies: T;
  dependenciesLookup?: (
    analysis: Analysis,
    analyses: readonly Analysis[],
  ) => {
    [K in T[number]]: IAnalysis | undefined;
  };
};

export const fromFormulaName = (
  analyses: readonly Analysis[],
  formulaName: string,
) => {
  return analyses.find(
    (analysis) => analysis.meta && analysis.meta.formulaName === formulaName,
  );
};

export const createFormula = <T extends readonly string[]>(
  dependencies: T,
  formula: RawFormula<T>,
  dependenciesLookup?: (
    analysis: Analysis,
    analyses: readonly Analysis[],
  ) => {
    [K in T[number]]: IAnalysis | undefined;
  },
): Formula<T> => ({ dependencies, formula, dependenciesLookup });

export function blocking(list: (undefined | Analysis | FormulaResult)[]) {
  return list.some((element) => {
    if (!element) {
      return true;
    }
    if (hasOwnProperty(element, "details")) {
      return !!(<Analysis>element).details?.blocked;
    } else {
      return !!(<FormulaResult>element).blocked;
    }
  });
}
