import { blocking, createFormula } from "../analysisFormulaUtils";
import { digestibility } from "../helpers/analysisFormulaConstants";

const dependencies = ["protein", "fat", "fibre", "ash"] as const;
const nfeDrysubst = createFormula(
  dependencies,
  (analysis, { fat, protein, ash, fibre }) => {
    if (!fat?.calc || !protein?.calc || !ash?.calc || !fibre?.calc) {
      return { value: 0 };
    }
    return {
      value: 1000 - +fat.calc - +protein.calc - +ash.calc - +fibre.calc,
    };
  },
);

export const metEnergyPerkg = createFormula(
  dependencies,
  (analysis, { protein, fat, fibre, ash }) => {
    if (
      !protein?.calc ||
      !fat?.calc ||
      !fibre?.calc ||
      !ash?.calc ||
      !analysis.productMeta?.material
    ) {
      return { value: 0 };
    }
    const nfegts = nfeDrysubst.formula(
      analysis,
      { fat, protein, ash, fibre },
      true,
      false,
    );
    const blocked = blocking([fibre, nfegts]);

    const digestibilityMap = digestibility(
      analysis.productMeta.material?.toString(),
    );
    return {
      value:
        (+protein.calc * (digestibilityMap.protein / 100) * 18.9 +
          +fat.calc * (digestibilityMap.fat / 100) * 34.8 +
          +fibre.calc * (digestibilityMap.fibre / 100) * 12.2 +
          nfegts.value * (digestibilityMap.nfe / 100) * 15.5) /
        1000,
      blocked,
    };
  },
);
