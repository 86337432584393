import BlockIcon from "@mui/icons-material/Block";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export const NoPermission: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "50vh",
      }}
    >
      <Box
        sx={{
          p: 4,
          textAlign: "center",
          border: 1,
          borderColor: "divider",
          borderRadius: 1,
        }}
      >
        <BlockIcon sx={{ fontSize: 40, color: "error.main", mb: 2 }} />
        <Typography variant="h6" gutterBottom>
          Åtkomst nekad
        </Typography>
        <Typography>Du saknar behörighet att visa denna sida</Typography>
      </Box>
    </Box>
  );
};
